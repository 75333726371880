export enum ButtonColors {
  white = 'white',
  gray = 'gray',
  blue = 'blue',
}

export interface ButtonArgs {
  type?: string;
  onClick?: () => void;
  title: string;
  color?: ButtonColors;
  link?: string;
  isExternal?: boolean;
  customClassName?: string;
  form?: string;
  disabled?: boolean;
}
