import React from 'react';

import { Select as SelectField, Loader } from '@periodica/ui-kit';

import { DELIVERY_TYPE_PICKUP, DELIVERY_TYPE_COURIER } from '@periodica/consts';

import { DELIVERY_DEFAULT_CITY } from '@consts/deliveryConsts';
import { humanizeDate } from '@utils/humanizeDate';
import { DeliveryInfo, ProvidersSwitcher, NoCityLine } from '@components/Delivery';
import type { CityType, DeliveryInfoArgs } from '@customTypes/delivery';
import { getDeliveryWidgetData } from '../../../periodicaApi/deliveryCalculator';
import { fetchCities } from '../../../periodicaApi/deliveryCities';

import { Switcher } from '../../Blocks/Switcher';

import styles from './DeliveryWidget.module.scss';

interface DeliveryWidgetArgs {
  productType: string;
  productSize: string;
}

const switcherOptions = [
  {
    id: DELIVERY_TYPE_PICKUP,
    title: 'Самовывоз из ПВЗ',
  },
  {
    id: DELIVERY_TYPE_COURIER,
    title: 'Курьером',
  },
];

// TODO: требуется рефакторинг
export function DeliveryWidget({ productType, productSize }: DeliveryWidgetArgs) {
  const [cities, setCities] = React.useState<CityType[]>([]);
  const [pickupCities, setPickupCities] = React.useState<CityType[]>([]);
  const [deliveryCities, setDeliveryCities] = React.useState<CityType[]>([]);
  const [selectedCity, setSelectedCity] = React.useState<CityType>(DELIVERY_DEFAULT_CITY);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasFetchingError, setHasFetchingError] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(DELIVERY_TYPE_PICKUP);
  const [deliveryInfo, setDeliveryInfo] = React.useState([]);
  const [deliveryProvider, setDeliveryProvider] = React.useState<DeliveryInfoArgs | null>(null);

  const isPickupDelivery = deliveryType === DELIVERY_TYPE_PICKUP;

  const handleFetchCities = async () => {
    setIsLoading(true);
    try {
      const citiesData = await fetchCities();
      const deliveryCitiesData = citiesData.filter((city) => city.courier);
      const pickupCitiesData = citiesData.filter((city) => city.pickup);
      setPickupCities(pickupCitiesData);
      setDeliveryCities(deliveryCitiesData);
      // @ts-ignore
      setCities(citiesData);
    } catch (err) {
      throw new Error(`Could not fetch cities list: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetDeliveryType = (type: string) => {
    if (type === deliveryType) {
      return;
    }
    setDeliveryProvider(null);
    setDeliveryType(type);
  };

  const handleGetDeliveryInfo = React.useCallback(async () => {
    if (productSize) {
      setIsLoading(true);
      setHasFetchingError(false);
      try {
        const deliveryData = await getDeliveryWidgetData({
          cityId: selectedCity.id,
          items: [{ type: productType, size: productSize, count: 1 }],
          isPickupDelivery: deliveryType === DELIVERY_TYPE_PICKUP,
        });
        setDeliveryInfo(deliveryData);
        setDeliveryProvider(deliveryData[0]);
      } catch (err) {
        setHasFetchingError(true);
        throw new Error(`Could not fetch delivery info: ${err}`);
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedCity.id, productType, productSize, deliveryType]);

  const handleSetDeliveryProvider = (selectedDeliveryProvider: DeliveryInfoArgs) => {
    setDeliveryProvider(selectedDeliveryProvider);
  };

  const handleChangeCity = (selectedCityId: string) => {
    const cityToSelect = cities.find((city) => city.id === Number(selectedCityId));
    // @ts-ignore
    setSelectedCity(cityToSelect);
  };

  React.useEffect(() => {
    handleGetDeliveryInfo();
  }, [handleGetDeliveryInfo, selectedCity.id, deliveryType, productSize]);

  React.useEffect(() => {
    handleFetchCities();
  }, []);

  return (
    <div className={styles.wrapper} id="delivery-widget">
      <h5>Узнать стоимость и срок получения заказа</h5>
      <Switcher
        activeOption={deliveryType}
        options={switcherOptions}
        onSwitch={handleSetDeliveryType}
        customClassName={styles.switcher}
        disabled={!selectedCity.courier || !selectedCity.pickup}
      />
      <SelectField
        name="city"
        onChange={(value: string) => handleChangeCity(value)}
        values={isPickupDelivery ? pickupCities : deliveryCities}
        value={selectedCity.id}
        customClassName={styles.citySelector}
      />
      {isLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          {!hasFetchingError ? (
            <>
              <ProvidersSwitcher
                deliveryInfo={deliveryInfo}
                selectedProvider={deliveryProvider}
                handleSwitchProvider={handleSetDeliveryProvider}
              />
              {!!deliveryProvider && (
                <>
                  <DeliveryInfo
                    mode="exact"
                    // @ts-ignore
                    deliveryDate={humanizeDate(deliveryProvider.raw_date)}
                    deliveryPrice={deliveryProvider.sum}
                  />
                  <NoCityLine />
                </>
              )}
            </>
          ) : (
            <p>
              Не удалось загрузить данные для этого продукта. Попробуйте обновить страницу или{' '}
              <a href="mailto:help@periodica.press">обратитесь в поддержку</a>
            </p>
          )}
        </>
      )}
    </div>
  );
}
